import { Provider } from '@angular/core';
import { casinoRecommendedCollection } from '@bcf-v2-configurators/tokens/casino/lvbetcom/casino-recommended-collection';
import { gamesImagesAssetsUrl } from '@bcf-v2-configurators/tokens/casino/lvbetcom/games-images-assets-url';
import { CASINO_RECOMMENDED_COLLECTION, GAMES_IMAGES_ASSETS_URL } from '@bcf-v2-configurators/tokens/casino/token';
import { I18N_DESC_LOADER } from '@bcf-v2-configurators/tokens/descs/token';
import {
  editProfileAddressAvailableFieldsFn,
  editProfilePrivacyAndNotificationsAvailableFields
} from '@bcf-v2-configurators/tokens/edit-profile/lvbetcom/edit-profile-available-fields';
import {
  EDIT_PROFILE_ADDRESS_AVAILABLE_FIELDS,
  EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_AVAILABLE_FIELDS
} from '@bcf-v2-configurators/tokens/edit-profile/token';
import { footerLinksFn } from '@bcf-v2-configurators/tokens/footer/lvbetcom/casino-footer-links';
import { footerBrandName } from '@bcf-v2-configurators/tokens/footer/lvbetcom/footer-brand-name';
import { FOOTER_BRAND_NAME, FOOTER_LINKS } from '@bcf-v2-configurators/tokens/footer/token';
import { imgWebConfig } from '@bcf-v2-configurators/tokens/img-webp/lvbetcom/img-web-config';
import { IMG_WEB_CONFIG } from '@bcf-v2-configurators/tokens/img-webp/token';
import { ladeskChatConfig } from '@bcf-v2-configurators/tokens/ladesk-chat/lvbetcom/ladesk-chat-config';
import { LADESK_CHAT_CONFIG } from '@bcf-v2-configurators/tokens/ladesk-chat/token';
import { lvbetcomLanguageSwitcherFlagMap } from '@bcf-v2-configurators/tokens/language-switcher/lvbetcom/lvbetcom-language-switcher-flag-map';
import { LANGUAGE_SWITCHER_FLAG_MAP } from '@bcf-v2-configurators/tokens/language-switcher/token';
import { lotteryBrandName } from '@bcf-v2-configurators/tokens/lottery/lvbet/lottery-brand-name';
import { LOTTERY_BRAND_NAME } from '@bcf-v2-configurators/tokens/lottery/token';
import { OPTIMOVE_SDK_CONFIG_LOADER } from '@bcf-v2-configurators/tokens/optimove-sdk/tokens';

export const settingsProviders: Provider[] = [
  {
    provide: IMG_WEB_CONFIG,
    useValue: imgWebConfig
  },
  {
    provide: I18N_DESC_LOADER,
    useValue: () =>
      import(
        /* webpackChunkName: "const-i18n-desc" */ '@bcf-v2-configurators/tokens/descs/lvbetcom/mobile-web/i18n-desc'
        // eslint-disable-next-line @typescript-eslint/typedef
      ).then((mod) => mod.i18nDesc)
  },
  {
    provide: GAMES_IMAGES_ASSETS_URL,
    useValue: gamesImagesAssetsUrl
  },
  {
    provide: CASINO_RECOMMENDED_COLLECTION,
    useValue: casinoRecommendedCollection
  },
  {
    provide: EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_AVAILABLE_FIELDS,
    useValue: editProfilePrivacyAndNotificationsAvailableFields
  },
  {
    provide: EDIT_PROFILE_ADDRESS_AVAILABLE_FIELDS,
    useValue: editProfileAddressAvailableFieldsFn
  },
  {
    provide: LADESK_CHAT_CONFIG,
    useValue: ladeskChatConfig
  },
  {
    provide: LANGUAGE_SWITCHER_FLAG_MAP,
    useValue: lvbetcomLanguageSwitcherFlagMap
  },
  {
    provide: FOOTER_LINKS,
    useValue: footerLinksFn
  },
  {
    provide: FOOTER_BRAND_NAME,
    useValue: footerBrandName
  },
  {
    provide: LOTTERY_BRAND_NAME,
    useValue: lotteryBrandName
  },
  {
    provide: OPTIMOVE_SDK_CONFIG_LOADER,
    useValue: () =>
      import('@bcf-v2-configurators/tokens/optimove-sdk/lvbetcom/casino/mobile-web/optimove-sdk-config').then(
        // eslint-disable-next-line @typescript-eslint/typedef
        (mod) => mod.optimoveSdkConfigs
      )
  }
];
