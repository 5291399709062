import { IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { EnvironmentProviders, Inject, makeEnvironmentProviders } from '@angular/core';
import { BCF_PRODUCTION_MODE } from '../app/token';
import { IMG_WEB_CONFIG } from './token';
import { ImgWebConfig } from './types';

export function provideImgWebLoader(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: IMAGE_LOADER,
      useFactory: (imgWebConfig: ImgWebConfig, isProductionMode: boolean) => (config: ImageLoaderConfig) => {
        const hostUrl: string = isProductionMode ? imgWebConfig.hostUrlProd : imgWebConfig.hostUrlDev;
        const src: string = `${hostUrl}get-webp/?url=${config.src}`;
        return src;
      },
      deps: [[new Inject(IMG_WEB_CONFIG)], [new Inject(BCF_PRODUCTION_MODE)]]
    }
  ]);
}
