/* eslint-disable @typescript-eslint/typedef */
import { Router } from '@angular/router';
import { SharedSettings } from '@bcf-shared-settings/settings/shared-settings';
import { lastValueFrom } from 'rxjs';

export const routerInitAfterEnvSetupDeps = [Router, SharedSettings];

export const routerInitAfterEnvSetup = (router: Router, sharedSettings: SharedSettings) => async (): Promise<void> => {
  await lastValueFrom(sharedSettings.isI18nReadyCold());
  await lastValueFrom(sharedSettings.isReadyCold());
  router.initialNavigation();
};
