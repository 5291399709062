/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { Routes } from '@angular/router';
import { environment } from '@env/env';

export const routes: Routes = [
  ...environment.langs.map((lang: string) => ({
    path: lang,
    loadChildren: async () => {
      const { LangRouteModule: LangRouteModule } = await import(
        /* webpackChunkName: "lang-route" */ './lang-route/lang-route.module'
      );
      return LangRouteModule;
    },
    data: {
      routeLang: lang
    }
  })),
  {
    path: '',
    loadComponent: async () => {
      const { DefaultLangRedirectorSmartComponent: DefaultLangRedirectorSmartComponent } = await import(
        /* webpackChunkName: "default-lang-redirector-smart" */ '@bcf-v2-ui-others-components-sportsbook-lvbetpl/mobile-web/default-lang-redirector-smart/default-lang-redirector-smart.component'
      );
      return DefaultLangRedirectorSmartComponent;
    }
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: async () => {
      const { NotFound404PageModule: NotFound404PageModule } = await import(
        /* webpackChunkName: "404-route" */ '@bcf-libs/ui/pages/casino/lvbetcom/mobile-web/src/lib/not-found404-page/not-found404-page.module'
      );
      return NotFound404PageModule;
    }
  }
];
