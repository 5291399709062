import { provideDiTokenValue } from '@bcf-vanilla-ts-v1-shared/di/di-token';
import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { isSSR } from '@bcf-vanilla-ts-v1-shared/misc/utils/is-ssr';
import { EMPTY, Observable, Subscriber, fromEvent, pluck } from 'rxjs';
import { globalThisEventTarget } from '../common/global-this-event-target';
import { WORKER_INSTANCE } from './tokens';

export abstract class WorkerWrapper {
  // biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation>
  public postMessage(_message: Record<string, any>): void {}

  public getMessage<T>(): Observable<T> {
    return EMPTY;
  }
}

export class WorkerWrapperInternal extends WorkerWrapper {
  constructor(private _worker: Worker) {
    super();
  }

  public override postMessage(message: Record<string, any>): void {
    try {
      this._worker.postMessage(this._tryParseMessage(message));
    } catch (_err: any) {
      if (this._dismissError(message)) {
        return;
      }
      console.error('BROWSER_SIDE # cloning problem message', message);
      throw new Error('BROWSER_SIDE # problem with cloning message from browser to worker');
    }
  }

  public override getMessage<T>(): Observable<T> {
    return new Observable((subscriber: Subscriber<T>) =>
      this._worker.addEventListener('message', (message: MessageEvent<T>) => subscriber.next(message.data))
    );
  }

  private _dismissError(message: Record<string, any>): boolean {
    if (message['channelName'] === '_workerSentryErrorHandler' && message['payload']?.['target'] instanceof WebSocket) {
      return true;
    }
    return false;
  }

  private _isHttpErrorResponse(message: object): boolean {
    if (message.hasOwnProperty('url') && message.hasOwnProperty('status')) {
      return true;
    }
    return false;
  }

  private _tryParseMessage(message: Record<string, any>): Record<string, any> {
    if (message.payload && this._isHttpErrorResponse(message.payload)) {
      message.payload = {
        url: message.payload.url,
        status: message.payload.status,
        message: message.payload.message
      };
    }
    return message;
  }
}

class WorkerWrapperVirtual extends WorkerWrapper {
  constructor(private _eventTarget: EventTarget) {
    super();
  }

  public override postMessage(message: Record<string, any>): void {
    this._eventTarget.dispatchEvent(new CustomEvent('virtualWorkerChannel', { detail: message }));
  }
  public override getMessage<T>(): Observable<T> {
    return fromEvent<CustomEvent<T>>(this._eventTarget, 'virtualWorkerChannel').pipe(pluck('detail'));
  }
}

export function provideWorkerWrapper(): WorkerWrapper {
  if (isSSR()) {
    return provideSingleton(WorkerWrapper, () => new WorkerWrapperVirtual(globalThisEventTarget()));
  }
  return provideSingleton(WorkerWrapper, () => new WorkerWrapperInternal(provideDiTokenValue(WORKER_INSTANCE)));
}
