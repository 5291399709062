import { DiToken } from '@bcf-vanilla-ts-v1-shared/di/di-token';
import { WorkerBlocRegistry } from '../common/types';
import { WorkerBlocId } from '../worker-blocs.type';

// biome-ignore lint/style/useNamingConvention: <explanation>
export const WORKER_BLOCS_REGISTRY: DiToken<WorkerBlocRegistry[]> = new DiToken<WorkerBlocRegistry[]>(
  'workerBlocsRegistry'
);

// biome-ignore lint/style/useNamingConvention: <explanation>
export const WORKER_BLOCS_REGISTRY_WHITELIST: DiToken<WorkerBlocId[]> = new DiToken<WorkerBlocId[]>(
  'workerBlocsRegistryWhitelist'
);

// biome-ignore lint/style/useNamingConvention: <explanation>
export const IS_WORKER: DiToken<boolean> = new DiToken<boolean>('isWorker');
