import { Inject, Injectable, Optional } from '@angular/core';
import { AFFILIATE_IDS_WITH_EXPIRATION_DATE } from '@bcf-v2-configurators/tokens/affiliate/token';
import { IndexdbStorage } from '@bcf-v2-utilities/indexdb-storage/indexdb-storage';
import { addDays, isBefore } from 'date-fns';

type AffiliateDataRaw = string;

type AffiliateDataInMemory = {
  affData: AffiliateDataRaw;
  createdAt: Date;
};

const affiliateDataValidityInDays: number = 60;

@Injectable({ providedIn: 'root' })
export class AffiliateDataMemory {
  constructor(
    private _indexdbStorage: IndexdbStorage,
    @Optional()
    @Inject(AFFILIATE_IDS_WITH_EXPIRATION_DATE)
    private _affiliateIdsWithExpirationDate?: number[]
  ) {}

  public async getDataRawFromMemory(): Promise<string | undefined> {
    const affiliateDataFromStorage: AffiliateDataInMemory | undefined =
      await this._indexdbStorage.get('affiliate-data');

    if (!affiliateDataFromStorage) {
      return undefined;
    }

    return affiliateDataFromStorage.affData;
  }

  public async updateDataRaw(affiliateData: string | undefined): Promise<void> {
    if (!affiliateData) {
      return;
    }

    const affiliateDataFromStorage: AffiliateDataInMemory | undefined =
      await this._indexdbStorage.get('affiliate-data');

    if (this._isActiveInMemory(affiliateDataFromStorage)) {
      return;
    } else {
      await this._indexdbStorage.delete('affiliate-data');
    }

    this._indexdbStorage.set('affiliate-data', {
      affData: affiliateData,
      createdAt: new Date()
    });
  }

  private _isActiveInMemory(affiliateData: AffiliateDataInMemory | undefined): boolean {
    if (
      affiliateData &&
      isBefore(new Date(), addDays(affiliateData.createdAt, affiliateDataValidityInDays)) &&
      this._affiliateIdsWithExpirationDate?.includes(Number(affiliateData.affData.split('_')[0]))
    ) {
      return true;
    }

    return false;
  }
}
