import { InjectionToken } from '@angular/core';
import type {} from '@angular/localize/init';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const IS_LOTTERY_ELEMENTS_TYPE: InjectionToken<boolean> = new InjectionToken<boolean>(
  'IS_LOTTERY_ELEMENTS_TYPE',
  {
    factory: () => false
  }
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DISABLE_LOTTERY_DELAY_OF_PRIZE_DRAWN_SIGNAL: InjectionToken<boolean> = new InjectionToken<boolean>(
  'DISABLE_LOTTERY_DELAY_OF_PRIZE_DRAWN_SIGNAL',
  {
    factory: () => false
  }
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const LOTTERY_BRAND_NAME: InjectionToken<string> = new InjectionToken<string>('LOTTERY_BRAND_NAME', {
  factory: () => $localize`Lottery`
});
