import { DiToken, registerDiToken } from '@bcf-vanilla-ts-v1-shared/di/di-token';
import { BcfAppId } from './types';

// biome-ignore lint/style/useNamingConvention: <explanation>
export const BCF_APP_ID: DiToken<BcfAppId> = new DiToken<BcfAppId>('bcfAppId');

// biome-ignore lint/style/useNamingConvention: <explanation>
export const APP_SERVER_COUNTRY_IP: DiToken<string> = new DiToken<string>('appServerCountryIp');

export function registerBcfAppId(bcfAppId: BcfAppId): void {
  registerDiToken(BCF_APP_ID, () => bcfAppId);
}
