import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, EnvironmentProviders, Inject, makeEnvironmentProviders } from '@angular/core';
import { ENV_BASE, EnvBase } from '@bcf-v2-configs/env-base';
import { extractCookies } from '@bcf-v2-utilities/cookies-provider/utils';
import { AffiliateDataMemory } from './affiliate-data-memory';

export function provideAffiliateData(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: AffiliateDataMemory,
      useClass: AffiliateDataMemory
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (document: Document, envBase: EnvBase, affiliateDataMemory: AffiliateDataMemory) => () => {
        const searchParams: URLSearchParams = new URLSearchParams(document.location.search);
        const extractedCookies: Record<string, string> = extractCookies(document.cookie);
        const btagCookieName: string | undefined = envBase.cookiesMap?.btag;
        if (searchParams.has('btag')) {
          affiliateDataMemory.updateDataRaw(searchParams.get('btag') ?? undefined);
          return;
        }

        // this can be removed after completely switching from cms_ee to bcf and opting out of saving affiliate data in cookies
        if (btagCookieName && extractedCookies[btagCookieName]) {
          affiliateDataMemory.updateDataRaw(extractedCookies[btagCookieName]);
          return;
        }
      },
      deps: [[new Inject(DOCUMENT)], [new Inject(ENV_BASE)], AffiliateDataMemory],
      multi: true
    }
  ]);
}
