import type {
  DeviceApiType,
  DeviceApiTypeExtended,
  DeviceBaseType,
  DeviceCmsType,
  DeviceInfoPayload,
  DeviceSpecificType,
  NavigatorWithUserAgent,
  WindowForDeviceInfo
} from './types';

function matchAppleMobileDeviceType(navigator: NavigatorWithUserAgent): boolean {
  return /iPad|iPhone|iPod/.test(navigator.userAgent ?? '');
}

function apiType(baseType: DeviceBaseType, window: WindowForDeviceInfo): DeviceApiType {
  if (isMobileApp(window, baseType)) {
    return 'mobile-app';
  }
  if (baseType === 'mobile-web') {
    return 'mobile';
  }
  if (baseType === 'tv') {
    return 'terminal';
  }
  if (baseType === 'terminal-top') {
    return 'terminal';
  }
  return 'web';
}

function apiTypeExtended(
  baseType: DeviceBaseType,
  window: WindowForDeviceInfo,
  navigator: NavigatorWithUserAgent
): DeviceApiTypeExtended {
  if (baseType === 'tv' || baseType === 'terminal-top') {
    return 'terminal';
  }
  if (baseType === 'mobile-web') {
    if (isIOSPwa(window, navigator)) {
      return 'ios (PWA)';
    }
    if (isAndroidPwa(window, navigator)) {
      return 'android (PWA)';
    }
    if (isAndroid(navigator)) {
      return 'android (mobile-web)';
    }
    if (isIos(window, navigator)) {
      return 'ios (mobile-web)';
    }
  }
  if (baseType === 'mobile-web-twa') {
    if (isIOSNative(window, navigator, baseType)) {
      return 'ios (native)';
    }
    if (isAndroidNative(window, navigator, baseType)) {
      return 'android (native)';
    }
  }
  if (baseType === 'web') {
    if (isPWA(window)) {
      return 'web (PWA)';
    }
    return 'web (website)';
  }
  if (baseType === 'banner' || baseType === 'banner-tv') {
    return 'web (website)';
  }
  return baseType as DeviceApiTypeExtended;
}

function cmsType(baseType: DeviceBaseType): DeviceCmsType {
  if (baseType === 'mobile-web-twa') {
    return 'mobile';
  }
  if (baseType === 'mobile-web') {
    return 'mobile';
  }
  if (baseType === 'tv') {
    return 'terminal';
  }
  if (baseType === 'terminal-top') {
    return 'terminal';
  }
  if (baseType === 'banner' || baseType === 'banner-tv') {
    return 'web';
  }
  return baseType;
}

function specificType(
  baseType: DeviceBaseType,
  isIos: boolean,
  isAndroid: boolean,
  isPwa: boolean
): DeviceSpecificType {
  if (baseType !== 'mobile-web' && baseType !== 'mobile-web-twa') {
    return undefined;
  }
  if (isIos) {
    return 'ios';
  }
  if (isAndroid) {
    return 'android';
  }
  if (isPwa) {
    return 'pwa';
  }
}

function isMobile(baseType: DeviceBaseType): boolean {
  return baseType === 'mobile-web' || baseType === 'mobile-web-twa';
}

function isMobileApp(window: WindowForDeviceInfo, baseType: DeviceBaseType): boolean {
  return baseType === 'mobile-web-twa' || window.hasOwnProperty('cordova');
}

function isIos(window: WindowForDeviceInfo, navigator: NavigatorWithUserAgent): boolean {
  return (matchAppleMobileDeviceType(navigator) && !window.MSStream) || isSafari(navigator);
}

function isSafari(navigator: NavigatorWithUserAgent): boolean {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent ?? '');
}

function isAndroid(navigator: NavigatorWithUserAgent): boolean {
  return /Android/.test(navigator.userAgent ?? '');
}

function isPWA(window: WindowForDeviceInfo): boolean {
  return ['fullscreen', 'standalone', 'minimal-ui'].some(
    (displayMode: string) => window.matchMedia?.(`(display-mode: ${displayMode})`).matches
  );
}

function isIOSNative(
  window: WindowForDeviceInfo,
  navigator: NavigatorWithUserAgent,
  baseType: DeviceBaseType
): boolean {
  return matchAppleMobileDeviceType(navigator) && Boolean(navigator.standalone) && isMobileApp(window, baseType);
}

function isAndroidNative(
  window: WindowForDeviceInfo,
  navigator: NavigatorWithUserAgent,
  baseType: DeviceBaseType
): boolean {
  return isAndroid(navigator) && isMobileApp(window, baseType);
}

function isIOSPwa(window: WindowForDeviceInfo, navigator: NavigatorWithUserAgent): boolean {
  return matchAppleMobileDeviceType(navigator) && !navigator.standalone && isPWA(window);
}

function isAndroidPwa(window: WindowForDeviceInfo, navigator: NavigatorWithUserAgent): boolean {
  return isAndroid(navigator) && isPWA(window);
}

export function extractDevice(
  baseType: DeviceBaseType,
  window: WindowForDeviceInfo,
  navigator: NavigatorWithUserAgent
): DeviceInfoPayload {
  const ios: boolean = isIos(window, navigator);
  const android: boolean = isAndroid(navigator);
  const pwa: boolean = isPWA(window);

  return {
    baseType: baseType,
    apiType: apiType(baseType, window),
    apiTypeExtended: apiTypeExtended(baseType, window, navigator),
    cmsType: cmsType(baseType),
    specificType: specificType(baseType, ios, android, pwa),
    isMobile: isMobile(baseType),
    isMobileApp: isMobileApp(window, baseType),
    isIos: ios,
    isAndroid: android,
    isPwa: pwa,
    isSafari: isSafari(navigator),
    isAndroidPwa: isAndroidPwa(window, navigator),
    isIOSPwa: isIOSPwa(window, navigator),
    isAndroidNative: isAndroidNative(window, navigator, baseType),
    isIOSNative: isIOSNative(window, navigator, baseType)
  };
}

export function extractDeviceSsr(baseType: DeviceBaseType, navigator: NavigatorWithUserAgent): DeviceInfoPayload {
  const ios: boolean = false;
  const android: boolean = isAndroid(navigator);
  const pwa: boolean = false;

  return {
    baseType: baseType,
    apiType: apiType(baseType, {}),
    apiTypeExtended: apiTypeExtended(baseType, {}, {}),
    cmsType: cmsType(baseType),
    specificType: specificType(baseType, ios, android, pwa),
    isMobile: isMobile(baseType),
    isMobileApp: false,
    isIos: ios,
    isAndroid: android,
    isPwa: pwa,
    isSafari: false,
    isAndroidPwa: false,
    isIOSPwa: false,
    isAndroidNative: false,
    isIOSNative: false
  };
}

export function createTestingDeviceInfoPayload(partial: Partial<DeviceInfoPayload>): DeviceInfoPayload {
  return {
    ...partial
  } as DeviceInfoPayload;
}
