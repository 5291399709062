import { inject, Injectable, OnDestroy } from '@angular/core';
import { TypedSubject } from '@bcf-vanilla-ts-v1-platforms/platform-worker/browser/types';
import { Observable } from 'rxjs';
import { ExtractedObservable, WorkerBlocChannels } from '../common/types';
import { WorkerBlocId } from '../worker-blocs.type';
import { BrowserSideBlocController } from './browser-side-bloc-controller';
import { Unsubscribable } from './unsubscribable';

@Injectable()
export class UnsubscribableWithWorkerBlocForService<R extends WorkerBlocChannels>
  extends Unsubscribable
  implements OnDestroy
{
  private _uniqueId!: string;
  private _workerBlocId!: WorkerBlocId;

  private _browserSideBlocController: BrowserSideBlocController<R> = inject(BrowserSideBlocController);

  protected _initWorkerBloc(workerBlocId: WorkerBlocId): void {
    this._workerBlocId = workerBlocId;
    this._uniqueId = BrowserSideBlocController.getId(workerBlocId);
    this._browserSideBlocController.sendCreateInstance(workerBlocId, this._uniqueId);
    this._sub = this._browserSideBlocController
      .workerBlocInitialized(this._workerBlocId, this._uniqueId)
      .subscribe(() => this._initializedBloc());
  }

  public init(_arg1?: unknown, _arg2?: unknown, _arg4?: unknown): void {}

  protected _initializedBloc(): void {}

  protected _subTo<K extends keyof R['rx'], V extends ExtractedObservable<R['rx'][K]>>(channelRx: K): Observable<V> {
    return this._browserSideBlocController.subTo(this._workerBlocId, this._uniqueId, channelRx);
  }

  protected _workerSubject<V extends TypedSubject<R['subjects'][K]>, K extends keyof R['subjects']>(subjectKey: K): V {
    return this._browserSideBlocController.workerSubject(this._workerBlocId, () => this._uniqueId, subjectKey);
  }

  protected _workerObservable<K extends keyof R['rx'], V extends ExtractedObservable<R['rx'][K]>>(
    channelRx: K
  ): Observable<V> {
    return this._browserSideBlocController.subTo(this._workerBlocId, this._uniqueId, channelRx);
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._browserSideBlocController.sendDestroyInstance(this._workerBlocId, this._uniqueId);
  }
}
