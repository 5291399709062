import { Injectable } from '@angular/core';
import { WorkerWrapperInternal as VanillaTsWorkerWrapper } from '@bcf-vanilla-ts-v1-platforms/platform-worker/browser/worker-wrapper';

@Injectable({
  providedIn: 'root'
})
export class WorkerWrapper extends VanillaTsWorkerWrapper {
  constructor(private _workerInner: Worker) {
    super(_workerInner);
  }
}
