import { Provider } from '@angular/core';
import { forceBackToCurrentLocation } from '@bcf-v2-configurators/tokens/cashier/mobile-web-twa/force-back-to-current-location';
import { FORCE_BACK_TO_CURRENT_LOCATION } from '@bcf-v2-configurators/tokens/cashier/token';

export const settingsProviders: Provider[] = [
  {
    provide: FORCE_BACK_TO_CURRENT_LOCATION,
    useValue: forceBackToCurrentLocation
  }
];
