import { InjectionToken } from '@angular/core';
import { CasinoRecommendedCollection, GamesImagesStyles } from './types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const GAMES_IMAGES_ASSETS_URL: InjectionToken<string> = new InjectionToken<string>('gamesImagesAssetsUrl');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const GAMES_IMAGES_STYLE: InjectionToken<GamesImagesStyles> = new InjectionToken<GamesImagesStyles>(
  'gamesImagesStyle',
  {
    factory: () => 'lvbet'
  }
);
// eslint-disable-next-line @typescript-eslint/naming-convention
export const CASINO_RECOMMENDED_COLLECTION: InjectionToken<CasinoRecommendedCollection> =
  new InjectionToken<CasinoRecommendedCollection>('casinoRecommendedCollection');
