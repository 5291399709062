import { mobileApplicationRoutePath } from '@bcf-v2/routes-landings/common/app/paths/mobile-application-route-path';
import { promotionsRoutePath } from '@bcf-v2/routes-statics/common/app/paths/promotions-route-path';
import { sitemapRoutePath } from '@bcf-v2/routes-statics/common/app/paths/sitemap-route-path';
import { spAboutUsRoutePath } from '@bcf-v2/routes-statics/common/app/paths/sp-about-us-route-path';
import { spPrivacyPolicyRoutePath } from '@bcf-v2/routes-statics/common/app/paths/sp-privacy-policy-route-path';
import { spResponsibleGamingRoutePath } from '@bcf-v2/routes-statics/common/app/paths/sp-responsible-gaming-route-path';
import { spTermsRoutePath } from '@bcf-v2/routes-statics/common/app/paths/sp-terms-route-path';
import { spVipClubRoutePath } from '@bcf-v2/routes-statics/common/app/paths/sp-vip-club-route-path';
import { supportRoutePath } from '@bcf-v2/routes-statics/common/app/paths/support-route-path';
import { cashoutRoutePath } from '@bcf-v2/routes-statics/sportsbook/app/paths/cashout-route-path';
import { liveCasinoRoutePath } from '@bcf-v2/routes/casino/app/paths/live-casino-route-path';
import { slotsRoutePath } from '@bcf-v2/routes/casino/app/paths/slots-route-path';
import { tableGamesRoutePath } from '@bcf-v2/routes/casino/app/paths/table-games-route-path';
import { tournamentsRoutePath } from '@bcf-v2/routes/casino/app/paths/tournaments-route-path';
import { homeRouteI18n } from '@bcf-v2/routes/common/app/i18n/home-route-i18n';
import { FooterLink } from '../types';

export const footerLinksFn = (): FooterLink[][] => [
  [
    {
      url: spAboutUsRoutePath(),
      title: $localize`About us`
    },
    {
      url: 'https://lvbetpartners.com/',
      title: $localize`Affiliates`
    },
    {
      url: 'https://lvbet.com/casino-blog/',
      title: $localize`Casino Blog`
    }
  ],
  [
    {
      url: homeRouteI18n(),
      title: $localize`Casino`
    },
    {
      url: 'https://lvbet.com/sports/',
      title: $localize`Sports`
    },
    {
      url: liveCasinoRoutePath(),
      title: $localize`Live Casino`
    },
    {
      url: slotsRoutePath(),
      title: $localize`Slots`
    },
    {
      url: tableGamesRoutePath(),
      title: $localize`Table Games`
    },
    {
      url: promotionsRoutePath(),
      title: $localize`Bonuses`
    }
  ],
  [
    {
      url: tournamentsRoutePath(),
      title: $localize`Tournaments`
    },
    {
      url: mobileApplicationRoutePath(),
      title: $localize`Mobile application`
    },
    {
      url: cashoutRoutePath(),
      title: $localize`Cashout`
    },
    {
      url: spVipClubRoutePath(),
      title: $localize`Vip Club`
    }
  ],
  [
    {
      url: supportRoutePath(),
      title: $localize`Support`
    },
    {
      url: spTermsRoutePath(),
      title: $localize`Terms and conditions`
    },
    {
      url: spResponsibleGamingRoutePath(),
      title: $localize`Responsible gaming`
    },
    {
      url: spPrivacyPolicyRoutePath(),
      title: $localize`Privacy Policy`
    },
    {
      url: sitemapRoutePath(),
      title: $localize`Sitemap`
    }
  ]
];
